.janewi-trainings {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    &.tab {
      & + label {
        background: $color-dim;
        padding: 20px;
        cursor: pointer;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        font-weight: $font-weight-bold;
        transition: background-color 200ms ease, color 200ms ease;
      }

      &:checked + label {
        background: $color-secondary;
        color: $color-background;
      }

      &:checked + label + .janewi-trainings-tab {
        display: block;
      }
    }

    &.accordion {
      & + label {
        padding: 10px;
        cursor: pointer;
        flex-grow: 1;
        display: flex;
        align-items: center;
        border: 2px solid $color-dim;
        font-weight: $font-weight-bold;
        color: $color-text;
        transition: background-color 200ms ease, border 200ms ease, color 200ms ease;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          fill: $color-dim;
          transform-origin: center center;
          transform: rotate(-90deg);
          transition: transform 200ms ease, fill 200ms ease;
        }
      }

      &:checked + label {
        color: $color-secondary;
        border: 2px solid $color-secondary;

        svg {
          fill: $color-secondary;
          transform: rotate(0);
        }
      }

      &:checked + label + .janewi-trainings-accordion {
        display: block;
      }
    }
  }

  .janewi-trainings-tab {
    display: none;
    order: 99;
    width: 100%;
    padding: 0 2px;
    margin-top: 8px;
  }

  .janewi-trainings-accordion {
    display: none;
    width: 100%;
  }

  .janewi-training {
    margin-bottom: 30px;

    .janewi-training-info {
      display: flex;
      margin: 0;
      flex-flow: row wrap;

      div {
        border: 3px solid $color-primary;
        padding: 3px 8px;
        margin: 0 10px 10px 0;
        font-weight: $font-weight-bold;
        flex-grow: 2;
        text-align: center;

        &.date {
          background: $color-primary;
          color: $color-background;
          flex-grow: 1;
          max-width: 150px;
        }

        &.time {
          flex-grow: 1;
          max-width: 150px;
        }
      }
    }
  }
}