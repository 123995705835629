.featured-wrapper {
  padding: 50px 30px 30px 30px;
  background: $color-background;

  .featured {
    background: $color-background;
    display: block;
    position: relative;

    .featured-image {
      margin-right: $grid-gutter-width;
      position: relative;
      align-self: start;

      &:before {
        content: "";
        position: absolute;
        top: -20px;
        left: -20px;
        background: $color-primary;
        width: 25%;
        height: 85%;
        z-index: 100;
      }

      img {
        z-index: 110;
        position: relative;

        @include media-breakpoint-up(sm) {
          width: 250px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }
}