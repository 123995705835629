.ccm-block-page-list-wrapper {
  .ccm-block-page-list-pages {
    .ccm-block-page-list-page-entry {
      margin: 0 0 30px 0;
      display: flex;
      flex-flow: row wrap;

      @include media-breakpoint-up(lg) {
        flex-flow: row nowrap;
      }

      .ccm-block-page-list-info {
        div {
          padding: 3px 8px;
          margin: 0 10px 10px 0;
          border: 3px solid $color-primary;
          font-weight: $font-weight-bold;
          text-align: center;
          background: $color-primary;
          color: $color-background;
          flex-grow: 0;
          width: 200px;
        }
      }

      .ccm-block-page-list-content {
        padding: 6px 0 0 0;

        @include media-breakpoint-up(lg) {
          padding: 6px 0 0 11px;
        }

        .ccm-block-page-list-title {
          margin: 0 0 10px -3px;
          border: 3px solid $color-background;
        }

        .ccm-block-page-list-page-entry-read-more {
          margin-top: 10px;
        }
      }
    }
  }
}