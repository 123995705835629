.content-wrapper.home {
  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 15vw;
    height: 400px;
    top: 0;
    left: 0;
    background: $color-primary;

    @include media-breakpoint-up(md) {
      width: 20vw;
      height: 500px;
    }
    @include media-breakpoint-up(lg) {
      width: 40vw;
      height: 600px;
    }
  }
}

.home-header {
  .page-title h1 {
    color: $color-primary;
  }

  img {
    margin-bottom: -1px;
  }

  .home-column-right {
    display: flex;
    flex-direction: column;
    height: 100%;

    .home-title {
      flex-grow: 1;

      &.mobile {
        @include media-breakpoint-up(xl) {
          padding-left: 600px;
        }
      }
    }

    .latest {
      min-height: 300px;
    }
  }
}