.panel {
  display: flex;
  flex-direction: column;

  &.panel-primary {
    background: $color-primary;
    padding: 20px 25px;
    color: $color-background;

    h1, h2, h3, h4, h5, h6, a {
      color: $color-background;
    }
  }

  .panel-body {
    flex-grow: 1;
  }

  .panel-actions {
    text-align: right;
  }
}