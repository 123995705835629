@import "../node_modules/bootstrap/scss/bootstrap-reboot";
@import "settings";

@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/mixins";

html {
  min-height: 100%;
  position: relative;
}

html, body {
  width: 100vw;
  overflow-x: hidden;
}

body {
  min-height: 100vh;

  &.no-scroll {
    position: fixed;
  }
}

.ccm-page {
  @import "typography";

  .content-wrapper {
    padding: $page-padding-small $page-padding-small $page-padding-medium $page-padding-small;

    @include media-breakpoint-up(md) {
      padding: $page-padding-medium $page-padding-medium $page-padding $page-padding-medium;
    }
    @include media-breakpoint-up(lg) {
      padding: $page-padding;
    }
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  @import "panel";
  @import "nav";
  @import "table";
  @import "home";
  @import "statistics";
  @import "featured";
  @import "peopledirectory";
  @import "trainings";
  @import "news";
  @import "footer";
}
