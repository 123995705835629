* {
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-bold;
  color: $color-primary;
  margin: 80px 0 0 0;
  padding: 0 0 20px 0;
  line-height: 1.5em;

  &.no-margin-top {
    margin-top: 0;
  }
}

h1 {
  font-size: 2.0rem;
  padding-bottom: 60px;

  @include media-breakpoint-up(xs) {
    font-size: 3.0rem;
  }
}

h2 {
  font-size: 1.7rem;
  padding-bottom: 30px;
  color: $color-text;

  @include media-breakpoint-up(xs) {
    font-size: 2.2rem;
  }
}

h3 {
  font-size: 1.3rem;

  @include media-breakpoint-up(xs) {
    font-size: 1.5rem;
  }
}

strong, b {
  font-weight: $font-weight-bold;
}

.page-title {
  padding-left: calc(15vw + 10px - #{$page-padding-small});
  margin-bottom: 2 * $page-padding-small;

  h1 {
    padding-bottom: 10px;
    color: $color-secondary;
  }

  @include media-breakpoint-up(md) {
    padding-left: calc(20vw + 30px - #{$page-padding-medium});
    margin-bottom: $page-padding-medium;

    h1 {
      padding-bottom: 30px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-left: calc(40vw + 50px - #{$page-padding});
    margin-bottom: $page-padding;
    padding-right: 40px;

    h1 {
      padding-bottom: 60px;
    }
  }

  p {
    @extend h1;
    color: $color-primary;
    padding-bottom: 10px;
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 15vw;
    height: calc(100% + #{$page-padding-small});
    top: -#{$page-padding-small};
    left: -#{$page-padding-small};
    background: $color-primary;

    @include media-breakpoint-up(md) {
      width: 20vw;
      height: calc(100% + #{$page-padding-medium});
      left: -#{$page-padding-medium};
      top: -#{$page-padding-medium};
    }
    @include media-breakpoint-up(lg) {
      width: 40vw;
      height: calc(100% + #{$page-padding});
      left: -#{$page-padding};
      top: -#{$page-padding};
    }
  }
}

a {
  color: $color-text;
  font-weight: $font-weight-bold;
  font-size: 0.9em;
  transition: color ease 200ms, border-color ease 200ms;
  border-bottom: 1px solid transparent;

  &:hover {
    text-decoration: none;
    color: $color-primary;
    border-color: $color-primary;
  }

  &.button {
    padding: 7px 20px;
    border: 2px solid $color-text;
    border-radius: 1000px;

    &.button-primary {
      border-color: $color-primary;
      transition: color ease 200ms, background-color ease 200ms;

      &:hover {
        color: $color-background;
        background: $color-primary;
      }
    }

    &.button-secondary {
      border-color: $color-secondary;
    }
  }
}