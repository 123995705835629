.janewi-peopledirectory {
  .janewi-peopledirectory-person {
    position: relative;
    margin-bottom: 30px;

    &:hover {
      .janewi-peopledirectory-overlay {
        height: 100%;

        .janewi-peopledirectory-content-wrapper {
          h3 {
            padding-bottom: 20px;
          }

          p {
            //transform: scale(1);
            height: 3em;
          }
        }
      }
    }

    .janewi-peopledirectory-overlay {
      background: rgba($color-primary, 0.8);
      position: absolute;
      bottom: 0;
      left: 0;
      height: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center;
      opacity: 1;
      transition: height 350ms ease-in-out;

      .janewi-peopledirectory-content-wrapper {
        h3, p {
          color: $color-background;
        }

        h3 {
          padding-bottom: 0;
          transition: padding-bottom 350ms ease-in-out;
        }

        p {
          height: 0;
          overflow: hidden;
          margin-bottom: 0;
          padding-bottom: 0;
          transition: height 350ms ease-in-out;
        }

        a:hover {
          color: $color-background;
          border-bottom-color: $color-background;
        }
      }
    }
  }
}