$color-primary: #EB1734;
$color-secondary: #086F9E;
$color-background: #FFFFFF;
$color-dim: #eeeeee;
$color-text: #000000;

$font-weight-normal: 300;
$font-weight-bold: 700;

$page-padding: 120px;
$page-padding-medium: 50px;
$page-padding-small: 10px;

$grid-breakpoints: (
        xxxs: 0,
        xxs: 320px,
        xs: 568px,
        sm: 667px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1600px
);
$container-max-widths: (
        xxs: 500px,
        xs: 568px,
        sm: 667px,
        md: 768px,
        lg: 900px,
        xl: 910px,
        xxl: 1200px
);
