.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  text-align: center;
  padding: 0;
  z-index: 1000;
  transition: background-color cubic-bezier(.46,.03,.52,.96) 300ms, height 0ms cubic-bezier(.46,.03,.52,.96) 300ms,  padding cubic-bezier(.46,.03,.52,.96) 300ms;

  .nav-button {
    width: 40px;
    height: 40px;
    position: absolute;
    top: $page-padding-small;
    right: $page-padding-small;
    border: 2px solid $color-primary;
    border-radius: 20px;
    padding: 5px;
    transition: border-color cubic-bezier(.46,.03,.52,.96) 500ms;

    @include media-breakpoint-up(md) {
      top: $page-padding-medium;
      right: $page-padding-medium;
    }
    @include media-breakpoint-up(lg) {
      top: $page-padding;
      right: $page-padding;
    }

    &:hover {
      a {
        border-bottom: 0 !important;
      }
    }

    a {
      display: block;
      width: 27px;
      height: 27px;
      transition: transform cubic-bezier(.46,.03,.52,.96) 500ms;
      transform-origin: center center;
      transform: rotate(45deg);

      svg {
        width: 27px;
        height: 27px;
        fill: $color-primary;
        transition: fill cubic-bezier(.46,.03,.52,.96) 500ms;
      }
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  nav {
    margin-top: 40px;
    transform: scale(0);
    transition: transform 150ms cubic-bezier(.46,.03,.52,.96) 0ms;

    ul {
      height: 60vh;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0;
      padding: 10vh 0 0 0;
      font-size: 1.5em;
      position: relative;

      a {
        color: $color-background;
      }
    }
  }

  &.nav-open {
    background: $color-primary;
    height: 100%;
    padding: $page-padding-small;
    transition: background-color cubic-bezier(.46,.03,.52,.96) 300ms, height 0ms, padding cubic-bezier(.46,.03,.52,.96) 300ms;

    @include media-breakpoint-up(md) {
      padding: $page-padding-medium;
    }
    @include media-breakpoint-up(lg) {
      padding: $page-padding;
    }

    .nav-button {
      border-color: $color-background;

      a {
        transform: rotate(270deg);

        svg {
          fill: $color-background;
        }
      }
    }

    nav {
      transform: scale(1);
      transition: transform 200ms cubic-bezier(.46,.03,.52,.96) 100ms;
    }
  }
}