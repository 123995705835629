footer {
  padding: 0;

  .map {
    iframe {
      width: 100%;
      height: 600px;
      display: block;
    }
  }

  .footer-content {
    padding: 50px $page-padding-small 50px $page-padding-small;
    background: $color-primary;
    color: $color-background;

    @include media-breakpoint-up(md) {
      padding: 50px $page-padding-medium 50px $page-padding-medium
    }
    @include media-breakpoint-up(lg) {
      padding: 50px $page-padding 50px $page-padding
    }

    .col-lg {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-align: center;

      @include media-breakpoint-up(lg) {
        min-height: 180px;
        text-align: left;
      }

      &:nth-of-type(2) {
        padding-top: 20px;

        @include media-breakpoint-up(lg) {
          text-align: right;
        }
      }

      .footer-content-bottom {
        margin-top: auto;
      }
    }

    a {
      color: $color-background;
    }

    ul {
      margin: 0;
      padding: 0 0 30px 0;

      li {
        display: inline-block;
        padding: 0 10px;
        border-right: 1px solid $color-background;

        &:last-of-type {
          border-right: none;
          padding-right: 0;
        }
      }
    }

    .copyright {
      font-size: 0.8em;
    }
  }
}