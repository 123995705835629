.statistics-wrapper {
  width: 100%;
  text-align: center;
  margin-top: -30px;

  .statistics {
    display: inline-block;
    width: 200px;
    text-align: left;
    padding-top: 30px;

    .statistics-number {
      font-size: 2.5rem;
      font-weight: $font-weight-bold;
      color: $color-background;
      background: $color-primary;
      padding: 10px 20px 60px 30px;

      p {
        margin: 0;
        padding: 0;
        font-weight: inherit;
      }

      a, a:hover, a:active, a:visited {
        color: $color-background;
        border-bottom: 0;
        font-weight: inherit;
      }
    }

    .statistics-caption {
      background: $color-secondary;
      color: $color-background;
      margin: -60px -20px 0 20px;
      padding: 10px;
      min-height: 80px;
      font-size: 1.2rem;

      p {
        margin: 0;
        padding: 0;
        font-weight: inherit;
      }

      a, a:hover, a:active, a:visited {
        color: $color-background;
        border-bottom: 0;
        font-weight: inherit;
      }
    }
  }
}